import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@awesome.me/kit-3f20e419a7/icons/classic/solid';
import Decimal from 'decimal.js';
import { t } from 'react-switch-lang';
import styles from '../styles/Testimonials.module.scss';

export default function RatingStars({ rating }) {
  const stars = [];

  // eslint-disable-next-line max-len
  for (let remainingStars = new Decimal(rating); stars.length < 5; remainingStars = remainingStars.minus(1)) {
    if (remainingStars >= 1) { // yellow star
      stars.push(<FontAwesomeIcon icon={faStar} color="#FFD800" key={remainingStars} />);
    } else if (remainingStars > 0) { // partial yellow star (hard coded SVG to use linearGradient)
      stars.push(
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" key={remainingStars}>
          <path
            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
            fill={`url(#partialStar${remainingStars})`}
          />
          <defs>
            <linearGradient id={`partialStar${remainingStars}`} x1="48" x2="528" y1="0" y2="0" gradientUnits="userSpaceOnUse">
              <stop offset={remainingStars} stopColor="#FFD800" />
              <stop offset={remainingStars} stopColor="#B9B9B9" />
            </linearGradient>
          </defs>
        </svg>
      );
    } else { // grey star
      stars.push(<FontAwesomeIcon icon={faStar} color="#B9B9B9" key={remainingStars} />);
    }
  }

  return (
    <div title={t('Testimonials_Rating_ToolTip').replace('xRating', rating)} className={styles.ratingContainer}>
      {stars}
    </div>
  );
}
