import ReactMarkdown from 'react-markdown';

const componentsMap = {
  strong: 'b',
  a: ({ node, children, ...props }) => <a {...props} target="_blank">{children}</a>,
};
const componentsMapWithNoPTag = {
  ...componentsMap,
  p: ({ children }) => children,
};

/**
 * @param {object} props
 * @param {string} [props.markdown] The markdown string to render.
 * @param {boolean} [props.explicitPTag] By default, texts won't be wrapped in <p> tags.
 *   Set this to true to use <p> tags.
 */
export default function RenderMarkdown({ markdown, explicitPTag = false }) {
  return (
    <ReactMarkdown components={explicitPTag ? componentsMap : componentsMapWithNoPTag}>
      {markdown}
    </ReactMarkdown>
  );
}
