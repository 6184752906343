import { Card } from 'react-bootstrap';
import { t } from 'react-switch-lang';
import styles from '../styles/Testimonials.module.scss';
import RenderMarkdown from './RenderMarkdown';
import ReplaceTextWithElement from './ReplaceTextWithElement';
import RatingStars from './RatingStars';

const typeLangKey = {
  CRA: 'Testimonials_CRA_PrefixText',
  Partner: 'Testimonials_Biller_PrefixText',
};
const paymentOptionLangKey = {
  Credit: 'Testimonials_Credit_Moneris_SuffixText',
  Moneris: 'Testimonials_Credit_Moneris_SuffixText',
  eTransfer: 'Testimonials_ETransfer_SuffixText',
  PayPal: 'Testimonials_PayPal_SuffixText',
  InPerson: 'Testimonials_PayPal_SuffixText',
};

export default function TestimonialCard({ rating, paymentOption, type, testimonial }) {
  return (
    <Card className={styles.testimonialCardContainer}>
      <Card.Body className={styles.testimonialCardBody}>
        <RatingStars rating={rating} />
        <p className={styles.testimonialText}>
          <RenderMarkdown markdown={testimonial} />
        </p>
        <p className={styles.testimonialUser}>
          <ReplaceTextWithElement
            text={`- ${t(typeLangKey[type])} ${t(paymentOptionLangKey[paymentOption])}`}
            replaceWith={<i>Interac</i>}
            replaceKey="Interac"
          />
        </p>
      </Card.Body>
    </Card>
  );
}
